import arrowDown from "../assets/img/arrow-down.svg";
import arrowUp from "../assets/img/arrow-up.svg";
import bank from "../assets/img/bank.svg";
import cc from "../assets/img/cc.svg";

export const cardsIcons = [
  {
    id: 3,
    icon: bank,
    title: "Saldo actual",
  },
  {
    id: 2,
    icon: arrowUp,
    title: "Ingresos",
  },
  {
    id: 1,
    icon: arrowDown,
    title: "Gastos",
  },

  {
    id: 4,
    icon: cc,
    title: "Tarjeta de credito",
  },
];
